import React, { useState } from "react";
import './index.css';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import toast from "react-hot-toast";
import { instance } from "../../../api/axios";
import { Clear } from "@mui/icons-material";

const AddWork = ({ setIsOpen, getData, tags }) => {
  const [name, setName] = useState("");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [tagId, setTagId] = useState("");
  const [text, setText] = useState("");

  const addImages = (e) => {
    const newImages = Array.from(e.target.files).filter(file => file.type.startsWith("image/"));
    setImages([...images, ...newImages]);
  };

  const addVideos = (e) => {
    const newVideos = Array.from(e.target.files).filter(file => file.type.startsWith("video/"));
    setVideos([...videos, ...newVideos]);
  };

  const removeImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const removeVideo = (index) => {
    const newVideos = [...videos];
    newVideos.splice(index, 1);
    setVideos(newVideos);
  };

  const closeHandler = () => setIsOpen(false);

  const handleAddWork = async (e) => {
    e.preventDefault();
    if(images.length === 0 || videos.length === 0){
      toast.error("Пожалуйста, добавьте изображения и видео.");
      return
    }
    const data = new FormData();
    try {
      // Append images
      images.forEach((image) => {
        data.append("images", image);
      });

      // Append videos
      videos.forEach((video) => {
        data.append("videos", video);
      });

      // Append other fields
      data.append("title", name);
      data.append("description", text);
      data.append("tagId", tagId);

      await instance.post(`/work/add`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      getData();
      setIsOpen(false);
      toast.success("Successfully added work.");
    } catch (error) {
      toast.error("Failed to add work.");
      console.error(error);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-[100vh] bg-modal2 flex items-center justify-center z-50">
      <form onSubmit={handleAddWork} className="rounded-md w-[90%] md:w-[50%] p-4 lg:w-[30%] overflow-y-auto max-h-[80vh] min-h-[340px] bg-white">
        <div className="w-full flex items-center justify-between text-[#343434] font-semibold text-[16px]">
          <p>Добавить работу</p>
          <svg
            onClick={closeHandler}
            className="cursor-pointer"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.468 11.468C11.2593 11.6767 11.0137 11.7808 10.7314 11.7803C10.449 11.7808 10.2035 11.6767 9.99482 11.468L6.31197 7.78515L2.62913 11.468C2.42043 11.6767 2.17466 11.781 1.89182 11.781C1.60996 11.781 1.36468 11.6767 1.15599 11.468C0.947292 11.2593 0.842699 11.0138 0.842208 10.7314C0.842699 10.4491 0.947292 10.2036 1.15599 9.99486L4.83883 6.31201L1.15599 2.62916C0.947292 2.42047 0.842945 2.1747 0.842945 1.89186C0.842945 1.61 0.947292 1.36472 1.15599 1.15602C1.36468 0.94733 1.61021 0.842737 1.89256 0.842246C2.17491 0.842737 2.42043 0.94733 2.62913 1.15603L6.31197 4.83887L9.99482 1.15603C10.2035 0.94733 10.4488 0.842983 10.7307 0.842983C11.0135 0.842983 11.2593 0.94733 11.468 1.15602C11.6767 1.36472 11.7808 1.61024 11.7803 1.89259C11.7808 2.17495 11.6767 2.42047 11.468 2.62916L7.78511 6.31201L11.468 9.99486C11.6767 10.2036 11.781 10.4488 11.781 10.7307C11.781 11.0135 11.6767 11.2593 11.468 11.468Z"
              fill="#333333"
            />
          </svg>
        </div>

        {/* Image Upload */}
        <div className="w-full mt-4">
          <label htmlFor="imageInput" className="custom-file-upload w-full">
            <input
              type="file"
              id="imageInput"
              multiple
              onChange={addImages}
              accept="image/*"
            />
            <span>Выберите изображения</span>
          </label>
        </div>

        {/* Display Selected Images */}
        <div className="w-full flex flex-wrap gap-2 my-4">
          {images.map((file, index) => (
            <div key={index} className="flex relative items-center justify-between w-[70px]">
              <img src={URL.createObjectURL(file)} alt="file" className="w-[50px] h-[50px] object-cover rounded-md" />
              <button type="button" onClick={() => removeImage(index)} className="absolute top-[-15px] right-[4px]">
                <Clear sx={{ color: "#ff0000" }} />
              </button>
            </div>
          ))}
        </div>

        {/* Video Upload */}
        <div className="w-full mt-4">
          <label htmlFor="videoInput" className="custom-file-upload w-full">
            <input
              type="file"
              id="videoInput"
              multiple
              onChange={addVideos}
              accept="video/*"
            />
            <span>Выберите видео</span>
          </label>
        </div>

        {/* Display Selected Videos */}
        <div className="w-full flex flex-wrap gap-2 my-4">
          {videos.map((video, index) => (
            <div key={index} className="flex relative items-center justify-between w-[70px]">
              <video
                src={URL.createObjectURL(video)}
                className="w-[50px] h-[50px] object-cover rounded-md"
                controls
              />
              <button type="button" onClick={() => removeVideo(index)} className="absolute top-[-15px] right-[4px]">
                <Clear sx={{ color: "#ff0000" }} />
              </button>
            </div>
          ))}
        </div>

        {/* Tag Selection */}
        <div className="flex w-full my-4">
          <select
            value={tagId}
            onChange={(e) => setTagId(e.target.value)}
            className="outline-none border w-full py-2 border-[#343434] rounded-md text-[#343434] px-3 text-[15px]"
          >
            <option value="">Выберите тег</option>
            {tags.map((tag) => (
              <option key={tag.id} value={tag.id}>
                {tag.name}
              </option>
            ))}
          </select>
        </div>

        {/* Title Input */}
        <div className="flex w-full my-4">
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            className="outline-none border w-full py-2 border-[#343434] rounded-md text-[#343434] px-3 text-[15px]"
            placeholder="Заголовок"
            required
          />
        </div>

        {/* Description Editor */}
        <div className="mt-4 w-full">
          <CKEditor
            editor={ClassicEditor}
            data={text}
            onChange={(event, editor) => {
              const data = editor.getData();
              setText(data);
            }}
          />
        </div>

        {/* Submit Button */}
        <button type="submit" className="w-full bg-[#343434] text-white font-semibold mt-4 py-2 rounded-lg">
          Добавить
        </button>
      </form>
    </div>
  );
};

export default AddWork;
